<template>
  <input :type="props.type ?? 'text'" class="text-input bg-transparent" :class="props.class" :min="props.min" v-model="props.modelValue" @input="update($event)" @blur="blur" @keyup.enter="enter" autocomplete="off" ref="element" />
</template>
<script setup>
const props = defineProps({
  modelValue: String,
  type: String,
  min: String,
  class: String
});

const element = ref(null);

const emit = defineEmits(['update:modelValue', 'validate', 'enter']);

const blur = ($event) => {
  emit('update:modelValue', $event.target.value);
  emit('validate');
}

const update = ($event) => {
  emit('update:modelValue', $event.target.value);
};

const enter = () => {
  emit('enter');
}

const focus = () => {
  element.value.focus();
}

defineExpose({ focus });

</script>
<style lang="postcss">
.text-input {
  @apply px-3 py-2 border-2 border-silver rounded-md;
}
</style>